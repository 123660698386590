import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterContainer>
      <p>2024 PS: Fuck You Roy</p>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  width: 100%;
  padding: 10px;
  background-color: #FFA500;
  color: #000;
  text-align: center;
  position: fixed;
  bottom: 0;
`;

export default Footer;
