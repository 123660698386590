import React from 'react';
import froyGif from './froy_gif.gif'; // Adjust the path accordingly

const Home = () => {
  return (
    <div className="container"><br />
      <div className="icons-container">
        <a href="https://dexscreener.com/seiv2/0xd4D953915C4765Ea3EC703ce840964A687563899" target="_blank" rel="noopener noreferrer">
          <img src="dexscreener.png" alt="Dexscreener" className="icon" />
        </a>
        <a href="https://discord.gg/WNwsWnzPKy" target="_blank" rel="noopener noreferrer">
          <img src="discord.jpg" alt="Discord" className="icon" />
        </a>
        <a href="https://t.me/+uGiRD515-uZjMTk0" target="_blank" rel="noopener noreferrer">
          <img src="telegram.png" alt="Telegram" className="icon" />
        </a>
        <a href="https://x.com/FROYlabs" target="_blank" rel="noopener noreferrer">
          <img src="twitter.png" alt="Twitter" className="icon" />
        </a>
      </div>
      <h1 className="title">SeiNetwork’s Community Memecoin</h1>
      <p className="description">Come $FROY with us!</p>
      <p className="highlight">🔥 50% OF THE TOTAL SUPPLY BURNED BY THE COMMUNITY 🔥</p>
      <p className="description">
        Buy yours on DragonSwap by manually importing the contract address and add them to the Liquidity Pool to take advantage of the insane APR!
      </p>
      <p className="contract-address">$FROY DISTRIBUTION: <a href="https://seitrace.com/token/0x9134d733AaC991b96E899af657C717276A627E32?tab=holders" target="_blank" rel="noopener noreferrer">https://seitrace.com/token/0x9134d733AaC991b96E899af657C717276A627E32?tab=holders</a></p>
      <p className="contract-address">$FROY CHART: <a href="https://dexscreener.com/seiv2/0xd4D953915C4765Ea3EC703ce840964A687563899" target="_blank" rel="noopener noreferrer">https://dexscreener.com/seiv2/0xd4D953915C4765Ea3EC703ce840964A687563899</a></p>
      <p className="contract-address">$FROY LIQUIDITY POOL: <a href="https://dragonswap.app/pools/0xd4D953915C4765Ea3EC703ce840964A687563899" target="_blank" rel="noopener noreferrer">https://dragonswap.app/pools/0xd4D953915C4765Ea3EC703ce840964A687563899</a></p>
      <img src={froyGif} alt="FROY Gif" className="gif" />
    </div>
  );
};

export default Home;
